import revive_payload_client_4sVQNw7RlN from "/home/runner/work/chat-faq/chat-faq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/home/runner/work/chat-faq/chat-faq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/chat-faq/chat-faq/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/runner/work/chat-faq/chat-faq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/chat-faq/chat-faq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/home/runner/work/chat-faq/chat-faq/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_8SbxDRbG6Y from "/home/runner/work/chat-faq/chat-faq/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_AOuQ1DYzjk from "/home/runner/work/chat-faq/chat-faq/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/chat-faq/chat-faq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import trpcClient_M8UiGTyzsx from "/home/runner/work/chat-faq/chat-faq/plugins/trpcClient.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  plugin_8SbxDRbG6Y,
  plugin_AOuQ1DYzjk,
  chunk_reload_client_UciE0i6zes,
  trpcClient_M8UiGTyzsx
]
<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style>
html {
  font-size: 16px;
}
* {
  font-family: "Inter", sans-serif;
  @apply text-sm font-medium text-default-text;
}
</style>
